import styled from "styled-components";

const InsightsCategoryContent = styled.section`
  position: relative;
  padding: 0 0 100px 0;
  display: flex;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    flex-direction: column;
  }

  .categories {
    padding-right: 20px;
    flex: 2;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
      padding-right: 0;
    }
  }

  .article-list {
    padding-left: 20px;
    flex: 5;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
      padding-left: 0;
    }
  }
`;

export default {
  InsightsCategoryContent,
};
