import React, { useContext } from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import S from "./styles";
import { SiteSettingsContext } from "../../../context/SiteSettingsContext";

const InsightsCategories = () => {
  const { settings } = useContext(SiteSettingsContext);
  const { categories } = useStaticQuery(graphql`
    query CategoriesListQuery {
      categories: allContentfulArticleCategory(
        filter: { slug: { nin: "dummy-article-category" } }
      ) {
        edges {
          node {
            title
            slug
          }
        }
      }
    }
  `);

  if (!categories.edges) return null;

  return (
    <S.InsightsCategories className="categories">
      <h3>Categories</h3>
      <ul>
        {categories.edges.map(category => (
          <li key={category.node.slug}>
            <AniLink
              cover
              bg="white"
              direction="right"
              to={`${settings.insightsPageSlug}/${category.node.slug}`}
            >
              {category.node.title}
            </AniLink>
          </li>
        ))}
      </ul>
    </S.InsightsCategories>
  );
};

export default InsightsCategories;
