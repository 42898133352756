import React, { useContext } from "react";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { useTheme } from "styled-components";
import RaindrumULogo from "../../common/Logos/RaindrumULogo";
import { SiteSettingsContext } from "../../../context/SiteSettingsContext";
import S from "./styles";

interface InsightsHeaderProps {
  quoteText?: string;
  quoteAuthor?: string;
}

const InsightsHeader = ({ quoteText, quoteAuthor }: InsightsHeaderProps) => {
  const { colors } = useTheme();
  const { settings } = useContext(SiteSettingsContext);

  return (
    <S.InsightsHeader>
      <header>
        <AniLink
          to={settings.insightsPageSlug}
          bg="white"
          cover
          direction="left"
        >
          <RaindrumULogo fill={colors.darkGrey} />
          <h1>Insights</h1>
        </AniLink>
      </header>
      {quoteText && (
        <div className="quote">
          <blockquote>
            {quoteText}
            {quoteAuthor && <div className="author">Thomas Edison</div>}
          </blockquote>
        </div>
      )}
    </S.InsightsHeader>
  );
};

export default InsightsHeader;
