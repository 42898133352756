import React, { useContext } from "react";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import S from "./styles";
import { SiteSettingsContext } from "../../../context/SiteSettingsContext";

const ArticleList = ({ articles }) => {
  if (!articles.edges) return null;

  const { settings } = useContext(SiteSettingsContext);

  return (
    <S.ArticleList className="article-list">
      {articles.edges.length ? (
        articles.edges
          .filter(a => a.node.slug != "dummy-article")
          .map(article => (
            <S.Article key={article.node.contentful_id}>
              <div className="type-category">{article.node.category.title}</div>
              <AniLink
                cover
                bg="white"
                direction="right"
                to={`${settings.insightsPageSlug}/${article.node.category.slug}/${article.node.slug}`}
              >
                <h3>{article.node.title}</h3>
              </AniLink>

              <div className="excerpt">{article.node.excerpt.excerpt}</div>
              <AniLink
                className="read-more"
                cover
                bg="white"
                direction="right"
                to={`${settings.insightsPageSlug}/${article.node.category.slug}/${article.node.slug}`}
              >
                Read More
              </AniLink>
            </S.Article>
          ))
      ) : (
        <h3 style={{ textAlign: "center" }}>
          Sorry, no articles to show in this category
        </h3>
      )}
    </S.ArticleList>
  );
};

export default ArticleList;
