import React from "react";
import S from "./styles";

const CategoryHeader = ({ title }) => {
  return (
    <S.CategoryHeader>
      <h2>{title}</h2>
    </S.CategoryHeader>
  );
};

export default CategoryHeader;
