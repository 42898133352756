import styled from "styled-components";
import InsightsMenuComponent from "../../layout/InsightsMenu";

const InsightsHeader = styled.section`
  position: relative;
  padding-top: 200px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    padding-top: 140px;
  }

  header {
    text-align: center;
    border-bottom: 1.5px solid ${({ theme }) => theme.colors.darkGrey};

    a {
      text-decoration: none;
    }

    h1 {
      font-size: 60px;
      line-height: 60px;
      margin-bottom: 60px;
    }
  }

  .quote {
    border-bottom: 1.5px solid ${({ theme }) => theme.colors.darkGrey};
  }

  blockquote {
    padding: 80px 0 60px 0;
    text-align: center;
    font-family: ${({ theme }) => theme.fonts.butlerMedium};
    font-weight: normal;
    font-size: 28px;
    line-height: 38px;
    letter-spacing: 0.6px;
    margin: 0 auto;
    max-width: 940px;

    .author {
      text-transform: uppercase;
      font-family: ${({ theme }) => theme.fonts.butlerMedium};
      font-size: 22px;
      padding-top: 10px;
    }
  }
`;

const InsightsMenu = styled(InsightsMenuComponent)`
  ul {
    position: relative;
    margin: 0;
    display: flex;
    justify-content: center;
    padding: 0;
    list-style: none;
    border-bottom: 1.5px solid ${({ theme }) => theme.colors.darkGrey};
    padding-bottom: 15px;

    letter-spacing: 1px;
    font-size: 20px;

    li {
      &:last-of-type {
        a {
          border-right: none;
        }
      }
    }

    a {
      color: ${({ theme }) => theme.colors.darkGrey};
      text-decoration: none;
      font-family: ${({ theme }) => theme.fonts.ttNormsBold};
      border-right: 2px solid ${({ theme }) => theme.colors.darkGrey};
      padding: 0 15px;
    }
  }
`;

export default {
  InsightsHeader,
  InsightsMenu,
};
