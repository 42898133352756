import styled from "styled-components";

const InsightsCategories = styled.div`
  position: relative;

  h3 {
    font-size: 24px;
    line-height: 36px;
    font-family: ${({ theme }) => theme.fonts.ttNormsBold};
    border-bottom: 1.5px solid ${({ theme }) => theme.colors.darkGrey};
    padding: 25px 0;
    margin: 0;
  }

  ul {
    position: relative;
    padding: 55px 0 30px 0;
    margin: 0;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
      padding: 30px 0;
    }

    li {
      display: block;

      a {
        font-family: ${({ theme }) => theme.fonts.ttNormsBold};
        font-size: 18px;
        line-height: 32px;
        text-decoration: none;
      }
    }
  }
`;

export default {
  InsightsCategories,
};
