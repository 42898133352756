import React, { useContext, useEffect } from "react";
import { graphql } from "gatsby";
import { SiteSettingsContext } from "../../context/SiteSettingsContext";
import { HeaderContext } from "../../context/HeaderContext";
import Layout from "../layout";
import Meta from "../../components/content/Meta";
import Container from "../../components/common/Container";
import InsightsHeader from "../../components/content/InsightsHeader";
import CategoryHeader from "../../components/content/CategoryHeader";
import InsightsCategoryContent from "../../components/content/InsightsCategoryContent";

export const query = graphql`
  query CategoryArticlesQuery($slug: String!) {
    articles: allContentfulArticle(
      sort: { fields: date, order: DESC }
      filter: { category: { slug: { eq: $slug, nin: "dummy-article" } } }
    ) {
      edges {
        node {
          title
          slug
          category {
            title
            slug
          }
          excerpt {
            excerpt
          }
        }
      }
    }
  }
`;

const ArticleCategory = ({ data, pathContext }) => {
  const { setTheme, setPageTheme, setHeaderVisible } = useContext(
    HeaderContext
  );
  const { setSettings } = useContext(SiteSettingsContext);

  useEffect(() => {
    if (pathContext.settings) {
      setSettings(pathContext.settings);
    }

    setTheme("dark");
    setPageTheme("dark");
    setHeaderVisible(true);
  }, [pathContext]);

  return (
    <Layout>
      <Meta
        meta={{
          title: `${pathContext.title} | Insights - Raindrum`,
          ogTitle: `${pathContext.title} | Insights - Raindrum`,
        }}
        defaults={pathContext.settings.defaultPageMetadata}
      />
      <Container>
        <InsightsHeader />
        <CategoryHeader title={pathContext.title} />
        <InsightsCategoryContent articles={data.articles} />
      </Container>
    </Layout>
  );
};

export default ArticleCategory;
