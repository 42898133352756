import styled from "styled-components";

const CategoryHeader = styled.div`
  position: relative;
  text-align: center;
  padding: 50px;
  border-bottom: 1.5px solid ${({ theme }) => theme.colors.darkGrey};

  h2 {
    font-size: 30px;
    line-height: 40px;
    margin: 0;
    text-transform: capitalize;
  }
`;

export default {
  CategoryHeader,
};
