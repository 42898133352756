import styled from "styled-components";

const ArticleList = styled.section`
  position: relative;
  padding: 80px 0;
`;

const Article = styled.article`
  position: relative;
  border-bottom: 1.5px solid ${({ theme }) => theme.colors.darkGrey};
  margin-bottom: 35px;
  padding-bottom: 40px;

  .type-category {
    font-size: 16px;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.darkGrey3};
  }

  h3 {
    font-size: 35px;
    line-height: 55px;
    margin: 15px 0 10px 0;
  }

  .excerpt {
    font-size: 20px;
    line-height: 28px;
  }

  a {
    text-decoration: none;
  }

  .read-more {
    position: relative;
    display: inline-block;
    font-size: 18px;
    line-height: 32px;
    letter-spacing: 1.3px;
    margin-top: 20px;
    font-family: ${({ theme }) => theme.fonts.ttNormsBold};
    text-decoration: none;
    text-transform: capitalize;
    color: ${({ theme }) => theme.colors.darkGrey};
    padding-bottom: 5px;
    ${({ theme }) => theme.mixins.underlineLink};
  }
`;

export default {
  ArticleList,
  Article,
};
