import React from "react";
import InsightsCategories from "../InsightsCategories";
import ArticleList from "../ArticleList";
import S from "./styles";
import { ContentfulInsightsHomeContent } from "../../../interfaces";

interface InsightsHomeContentProps extends ContentfulInsightsHomeContent {}

const InsightsCategoryContent = ({ articles }) => {
  return (
    <S.InsightsCategoryContent>
      <InsightsCategories />
      <ArticleList articles={articles} />
    </S.InsightsCategoryContent>
  );
};

export default InsightsCategoryContent;
